.video {
  position: absolute;
  z-index: -1;
  top: 15vh;
  left: 0;
  width: 100vw;
  height: 85vh;
  object-fit: cover;
  align-items: left;
}
