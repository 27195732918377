@font-face {
  src: url("../fonts/FuturaBoldfont.ttf");
  font-family: FuturaBoldfont;
}

.Headercontainer {
  display: inline-block;
  width: 100vw;
  margin-top: 5px;
}

.Buttoncontainer {
  position: absolute;
  justify-content: center;
  text-align: center;
  top: 11vh;
  right: 20vw;
  left: 20vw;
  margin-top: 0px;
}

.Header {
  margin: 0 auto;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
  max-width: 100vw;
}

.HeaderBackground {
  padding: 0.5vh 0vw;
  margin: 0 auto;
  height: 9vh;
  width: 100vw;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
  justify-content: center;
  align-content: center;
  align-items: center;
  float: center;
  align-self: center;
}

.SMSimage {
  height: 4vh;
  width: 4vh;
  padding-left: 0.6vw;
}

#title {
  font-size: 3vh;
  text-align: center;
  font-family: FuturaBoldfont;
  font-weight: bold;
  display: inline-block;
}

#walletButton {
  text-align: center;
  line-height: 0vh;
  margin-right: 10px;
  font-family: FuturaBoldfont;
  font-weight: bold;
  font-size: 4vw;
  padding: 2vh 5vw;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
  white-space: normal;
  border: none;
  cursor: pointer;
  opacity: 0.85;
}

.subheader {
  font-family: minecraft;
  font-size: 9vw;
  position: absolute;
  top: 5vh;
  left: 1vw;
  right: 1vw;
}
