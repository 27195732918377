@font-face {
  src: url("./fonts/minecraft.ttf");
  font-family: minecraft;
}

.flexboxcontainer {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  position: relative;
  bottom: 21vh;
}

.countdownlabels {
  font-family: futuraMedium;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
}

.countdownnumbers {
  font-family: minecraft;
  font-size: 13vh;
  line-height: 75px;
  /* font-weight: bold; */
  padding-top: 0px;
  margin-top: 0px;
}

.invisible {
  font-family: futur;
  font-size: 2.5vh;
  opacity: 0;
}

.flexbox1 {
  margin: 10px;
  text-align: center;
  justify-content: center;
}

.flexboxcolon {
  margin: 10px;
  text-align: center;
  justify-content: center;
}

.flexcontainer {
  display: flex;
  /* max-width: 20vw; */
  justify-content: center;
}

.flexcontainer div {
  padding: 0px;
}

.countdownText {
  color: #ffffff;
  font-size: 3vh;
  font-family: futur;
}
