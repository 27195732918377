.video {
  position: absolute;
  z-index: -1;
  top: 9vh;
  left: 0;
  width: 100vw;
  height: 91vh;
  object-fit: cover;
  align-items: left;
  background-color: aqua;
}
