@font-face {
  src: url("../fonts/FuturaBoldfont.ttf");
  font-family: MotivaSansMedium;
}

@font-face {
  src: url("../fonts/FuturaHeavyItalicfont.ttf");
  font-family: futuraItalic;
}

.Minter {
  justify-content: center;
  /* max-width: 90vw; */
  align-items: center;
  float: center;
  margin: 5px;
  /* opacity: 90%; */
}

.Minter .textwrapper {
  position: relative;
  top: 5vh;
  margin-top: 0px;
  display: inline-block;
  /* text-align: center; */
}

.form {
  padding: 2vh 7vw;
  /* max-height: 800px; */
  /* max-width: 400px; */
  width: 80vw;
  border-color: #1c1c1d;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
  opacity: 0.9;
}

.formTitle {
  padding: 8px 16px;
  padding-bottom: 0px;
  color: #ffffff;
  font-size: 7.5vw;
  font-family: futuraItalic;
  margin: 0;
}

.pricespan {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 6vw;
  float: right;
}

.middle {
  text-align: left;
  justify-content: left;
  align-items: left;
}

.total {
  font-family: futuraMedium;
  font-size: 6vw;
  float: left;
}

#mintButton {
  margin-top: 40px;
  background-color: #ffffff;
  color: #1c1c1d;
  font-family: MotivaSansMedium;
  font-size: 8vw;
  padding: 0.5vh 30%;
  cursor: pointer;
  border-radius: 12px;
  border: none;
}

#status {
  color: rgb(255, 255, 255);
  font-family: futuraMedium;
  font-size: 3vw;
}

#nftSupply {
  font-family: futuraMedium;
  font-size: 3vw;
  padding-bottom: 1vh;
  margin: 0;
  padding-top: 0;
}

.fee {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 4vw;
  float: right;
}
