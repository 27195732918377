@font-face {
  src: url("./fonts/FuturaBoldfont.ttf");
  font-family: MotivaSansMedium;
}

@font-face {
  src: url("./fonts/FuturaHeavyItalicfont.ttf");
  font-family: futuraItalic;
}

.Minter {
  justify-content: center;
  /* max-width: 90vw; */
}

.Minter .textwrapper {
  position: relative;
  top: 13vh;
  margin-top: 0px;
  display: inline-block;
  /* text-align: center; */
}

.form {
  padding: 2vh 2vw;
  /* max-height: 800px; */
  /* max-width: 400px; */
  width: 40vh;
  border-color: #1c1c1d;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
}

.formTitle {
  padding: 8px 16px;
  padding-bottom: 0px;
  color: #ffffff;
  font-size: 3.5vh;
  font-family: futuraItalic;
  margin: 0;
}

.pricespan {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: right;
}

.middle {
  text-align: left;
  justify-content: left;
  align-items: left;
}

.total {
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: left;
}

#mintButton {
  margin-top: 40px;
  background-color: #ffffff;
  color: #1c1c1d;
  font-family: MotivaSansMedium;
  font-size: 4vh;
  padding: 0.5vh 30%;
  cursor: pointer;
  border-radius: 12px;
  border: none;
}

#status {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
}

#nftSupply {
  font-family: MotivaSansMedium;
  font-size: 1.5vh;
  padding-bottom: 1vh;
  margin: 0;
  padding-top: 0;
}

.fee {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 2vh;
  float: right;
}
