@font-face {
  src: url("./fonts/FuturaBoldfont.ttf");
  font-family: FuturaBoldfont;
}

@font-face {
  src: url("./fonts/MotivaSansBold.woff.ttf");
  font-family: MotivaSansBold;
}

@font-face {
  src: url("./fonts/MotivaSansExtraBold.ttf");
  font-family: MotivaSansExtraBold;
}

@font-face {
  src: url("./fonts/MotivaSansMedium.woff.ttf");
  font-family: MotivaSansMedium;
}

@font-face {
  src: url("./fonts/minecraft.ttf");
  font-family: minecraft;
}

.flexboxcontainer {
  display: flex;
  align-items: center;
}

.SMScontainer {
  width: 30vw;
  margin: 10px;
}

.Headercontainer {
  width: 40vw;
  margin: 10px;
  text-align: center;
  font-family: futuraBoldFont;
  font-weight: bold;
}

.Buttoncontainer {
  width: 30vw;
  margin: 10px;
}

.Header {
  margin: 0 auto;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
  max-width: 100vw;
}

.HeaderBackground {
  padding: 0.5vh 3vw;
  margin: 0 auto;
  height: 15vh;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
}

.SMSimage {
  height: 4vh;
  width: 4vh;
  padding-left: 0.6vw;
}

#title {
  font-size: 3vh;
  text-align: center;
  font-family: futuraBoldFont;
  /* font-weight: bold; */
}

#walletButton {
  text-align: center;
  line-height: 2vh;
  font-family: futuraBoldFont;
  font-weight: bold;
  font-size: 2vh;
  padding: 1vh 1vw;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
  white-space: normal;
  border: none;
  cursor: pointer;
}

.subheader {
  font-family: minecraft;
  font-size: 10vh;
  position: absolute;
  top: 5vh;
  left: 1vw;
  right: 1vw;
}
