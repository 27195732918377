body {
  margin: 0;
  font-family: "Rubik";
  color: #ffffff;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* for minter status colour on ios and OSX */

a:any-link {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
  text-decoration: none;
}

/* for minter status colour on ios and OSX */

a:any-link:active {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

/* button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #000000;
  color: #ffffff;
  background-color: rgb(0, 0, 0);
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
} */

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}
