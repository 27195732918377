@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@font-face {
  src: url("../fonts/minecraft.ttf");
  font-family: minecraft;
}

.flexboxcontainer {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  position: relative;
  bottom: 11.5vh;
  left: 0vw;
}

/* test */

.countdownlabels {
  font-family: futuraMedium;
  font-size: 1.5vh;
  font-weight: bold;
}

.countdownnumbers {
  font-family: minecraft;
  font-size: 22.5vw;
  line-height: 50%;
  /* background-color: aquamarine; */
  /* font-weight: bold; */
}

.invisible {
  font-family: futur;
  font-size: 2vh;
  opacity: 0;
}

.flexbox1 {
  margin: 5px;
  text-align: center;
  justify-content: center;
}

.flexboxcolon {
  margin: 5px;
  text-align: center;
  justify-content: center;
}

.flexcontainer {
  display: flex;
  /* max-width: 20vw; */
  justify-content: center;
}

.flexcontainer div {
  padding: 0px;
}
