.mainContainer {
  position: fixed;
  left: 5vw;
  font-family: minecraft;
  width: 20vw;
  top: 30vh;
  /* line-height: 5vh; */
  align-items: center;
}

.infoContainer {
  color: rgb(255, 255, 255);
  font-size: 6vh;
  align-items: flex-start;
  display: inline;
  font-weight: normal;
  line-height: 7vh;
  /* background-color: blueviolet; */
  margin-bottom: 0;
}
.bluetext {
  color: rgb(0, 204, 255);
}
.borderContainer {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 20px;
  display: inline-block;
  background-color: rgb(255, 0, 113, 0.8);
  /* opacity: 0.5; */
}

.bottomText {
  position: relative;
  bottom: 2vh;
  line-height: 1vh;
  font-size: 5vh;
}

.links {
  position: relative;
  bottom: 6vh;
  font-size: 5vh;
  font-family: minecraft;
  text-decoration: underline;
  margin-left: 2vw;
  margin-right: 2vw;
}

.FooterBackground {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.TermsAndConditions {
  font-size: 3vh;
  font-family: futuramediumbt;
  color: white;
  cursor: pointer;
}

/* The Modal (background) */
.TermsModal {
  display: inline-block;
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 25%;
  margin: auto;
  top: 40vh;
  width: 50%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Modal Content/Box */
.TermsModalTitle {
  padding-right: 3vw;
  margin: 1% auto; /* 15% from the top and centered */
  width: 100%; /* Could be more or less, depending on screen size */
  font-family: FuturaBoldFont;
  color: white;
  font-size: 5vh;
}

.TermsModalText {
  color: white;
  margin-bottom: 3vh;
  padding-bottom: 5vh;
  font-size: 2.5vh;
}

.TermsModalContent {
  background-color: #000000be;
  color: white;
  font-size: 3vh;
  margin: 0% auto; /* 15% from the top and centered */
  padding: 0px;
  /* border: 2px solid #692626; */
  width: 100%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.TermsModalCloseButton {
  color: rgb(255, 255, 255);
  padding: 10px;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.TermsModalCloseButton:hover,
.TermsModalCloseButton:focus {
  color: rgb(255, 0, 113, 0.8);
  text-decoration: none;
  cursor: pointer;
}

.TermsModalContent {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: black;
}

.bolditems {
  font-weight: bold;
}

.listitems {
  text-align: left;
  /* list-style-type: circle; */
}

.subjecttoterms {
  font-size: 3vh;
  line-height: 1vh;
  cursor: pointer;
  text-decoration: underline;
}
