@font-face {
  src: url("./fonts/futuramediumbt.ttf");
  font-family: futuraMedium;
}

@font-face {
  src: url("./fonts/FuturaExtraBlackfont.ttf");
  font-family: futuraextrablack;
}

.qtySelector {
  margin: "auto";
  display: "block";
  width: "fit-content";
  font-family: futuraMedium;
  font-size: 2vh;
}

.max {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: right;
}

.numberOfMints {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 3.5vh;
}

.amount {
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: left;
}

.Amount {
  text-align: center;
  float: left;
}

#selectButtons {
  background-color: #1c1c1d;
  color: #ffffff;
  /* border-color: #1c1c1d; */
  /* box-shadow: 10px 5px 5px #1c1c1d; */
  display: inline-block;
  font-family: futuraextrablack;
  font-size: 4vh;
  padding: 0px 1vw;
  /* max-height: 40px; */
  border: none;
  cursor: pointer;
}

#maxButton {
  background-color: #ffffff;
  border-color: #1c1c1d;
  color: #1c1c1d;
  box-shadow: 0px 0px;
  font-family: futuraextrablack;
  font-size: 2vh;
  padding: 0px 0.75vw;
  cursor: pointer;
  border: none;
  border-radius: 25px;
}
