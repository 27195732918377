@font-face {
  src: url("./fonts/futuramediumbt.ttf");
  font-family: futuramediumbt;
}

@font-face {
  src: url("./fonts/FuturaBoldfont.ttf");
  font-family: FuturaBoldFont;
}

.FooterBackground {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.TermsAndConditions {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: white;
  cursor: pointer;
}

/* The Modal (background) */
.TermsModal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.TermsModalTitle {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  width: 80%; /* Could be more or less, depending on screen size */
  font-family: FuturaBoldFont;
  font-size: 3vh;
}

.TermsModalContent {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.TermsModalCloseButton {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.TermsModalCloseButton:hover,
.TermsModalCloseButton:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.TermsModalContent {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: black;
}

.bolditems {
  font-weight: bold;
}

.listitems {
  text-align: left;
  /* list-style-type: circle; */
}
