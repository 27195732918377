@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
body {
  margin: 0;
  font-family: "Rubik";
  color: #ffffff;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* for minter status colour on ios and OSX */

a:-webkit-any-link {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
  text-decoration: none;
}

a:any-link {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
  text-decoration: none;
}

/* for minter status colour on ios and OSX */

a:-webkit-any-link:active {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
  text-decoration: none;
}

a:any-link:active {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

/* button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #000000;
  color: #ffffff;
  background-color: rgb(0, 0, 0);
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
} */

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

.App {
  text-align: center;
  /* background-color: #ff0071; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ff0071;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  src: url(/static/media/FuturaBoldfont.7bfa5614.ttf);
  font-family: MotivaSansMedium;
}

@font-face {
  src: url(/static/media/FuturaHeavyItalicfont.815a0751.ttf);
  font-family: futuraItalic;
}

.Minter_Minter__7dawC {
  justify-content: center;
  /* max-width: 90vw; */
}

.Minter_Minter__7dawC .Minter_textwrapper__2atDb {
  position: relative;
  top: 13vh;
  margin-top: 0px;
  display: inline-block;
  /* text-align: center; */
}

.Minter_form__-00X8 {
  padding: 2vh 2vw;
  /* max-height: 800px; */
  /* max-width: 400px; */
  width: 40vh;
  border-color: #1c1c1d;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
}

.Minter_formTitle__17uHD {
  padding: 8px 16px;
  padding-bottom: 0px;
  color: #ffffff;
  font-size: 3.5vh;
  font-family: futuraItalic;
  margin: 0;
}

.Minter_pricespan__2zGD9 {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: right;
}

.Minter_middle__2eFGW {
  text-align: left;
  justify-content: left;
  align-items: left;
}

.Minter_total__2gxVp {
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: left;
}

#Minter_mintButton__3qNdJ {
  margin-top: 40px;
  background-color: #ffffff;
  color: #1c1c1d;
  font-family: MotivaSansMedium;
  font-size: 4vh;
  padding: 0.5vh 30%;
  cursor: pointer;
  border-radius: 12px;
  border: none;
}

#Minter_status__1uBmx {
  color: rgb(255, 255, 255);
  font-family: MotivaSansMedium;
  font-size: 2vh;
}

#Minter_nftSupply__2XO-j {
  font-family: MotivaSansMedium;
  font-size: 1.5vh;
  padding-bottom: 1vh;
  margin: 0;
  padding-top: 0;
}

.Minter_fee__xbXVr {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 2vh;
  float: right;
}

@font-face {
  src: url(/static/media/futuramediumbt.4e762c29.ttf);
  font-family: futuraMedium;
}

@font-face {
  src: url(/static/media/FuturaExtraBlackfont.bf8e35c1.ttf);
  font-family: futuraextrablack;
}

.QuantitySelector_qtySelector__DTMK1 {
  margin: "auto";
  display: "block";
  width: "fit-content";
  font-family: futuraMedium;
  font-size: 2vh;
}

.QuantitySelector_max__35dLx {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: right;
}

.QuantitySelector_numberOfMints__3uvzf {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 3.5vh;
}

.QuantitySelector_amount__EEiGV {
  font-family: futuraMedium;
  font-size: 3.5vh;
  float: left;
}

.QuantitySelector_Amount__3X4Y5 {
  text-align: center;
  float: left;
}

#QuantitySelector_selectButtons__3C19a {
  background-color: #1c1c1d;
  color: #ffffff;
  /* border-color: #1c1c1d; */
  /* box-shadow: 10px 5px 5px #1c1c1d; */
  display: inline-block;
  font-family: futuraextrablack;
  font-size: 4vh;
  padding: 0px 1vw;
  /* max-height: 40px; */
  border: none;
  cursor: pointer;
}

#QuantitySelector_maxButton__3kRlT {
  background-color: #ffffff;
  border-color: #1c1c1d;
  color: #1c1c1d;
  box-shadow: 0px 0px;
  font-family: futuraextrablack;
  font-size: 2vh;
  padding: 0px 0.75vw;
  cursor: pointer;
  border: none;
  border-radius: 25px;
}

@font-face {
  src: url(/static/media/FuturaBoldfont.7bfa5614.ttf);
  font-family: FuturaBoldfont;
}

@font-face {
  src: url(/static/media/MotivaSansBold.woff.f39c6a7c.ttf);
  font-family: MotivaSansBold;
}

@font-face {
  src: url(/static/media/MotivaSansExtraBold.d3f307c0.ttf);
  font-family: MotivaSansExtraBold;
}

@font-face {
  src: url(/static/media/MotivaSansMedium.woff.36cb9727.ttf);
  font-family: MotivaSansMedium;
}

@font-face {
  src: url(/static/media/minecraft.13b1d212.ttf);
  font-family: minecraft;
}

.Header_flexboxcontainer__1dyx0 {
  display: flex;
  align-items: center;
}

.Header_SMScontainer__3S6G5 {
  width: 30vw;
  margin: 10px;
}

.Header_Headercontainer__Uf_Uo {
  width: 40vw;
  margin: 10px;
  text-align: center;
  font-family: futuraBoldFont;
  font-weight: bold;
}

.Header_Buttoncontainer__3duTF {
  width: 30vw;
  margin: 10px;
}

.Header_Header__1fHt3 {
  margin: 0 auto;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
  max-width: 100vw;
}

.Header_HeaderBackground__12GKL {
  padding: 0.5vh 3vw;
  margin: 0 auto;
  height: 15vh;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
}

.Header_SMSimage__2JuhP {
  height: 4vh;
  width: 4vh;
  padding-left: 0.6vw;
}

#Header_title__2VpMf {
  font-size: 3vh;
  text-align: center;
  font-family: futuraBoldFont;
  /* font-weight: bold; */
}

#Header_walletButton__1EkE8 {
  text-align: center;
  line-height: 2vh;
  font-family: futuraBoldFont;
  font-weight: bold;
  font-size: 2vh;
  padding: 1vh 1vw;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
  white-space: normal;
  border: none;
  cursor: pointer;
}

.Header_subheader__1Fvkh {
  font-family: minecraft;
  font-size: 10vh;
  position: absolute;
  top: 5vh;
  left: 1vw;
  right: 1vw;
}

@font-face {
  src: url(/static/media/futuramediumbt.4e762c29.ttf);
  font-family: futuramediumbt;
}

@font-face {
  src: url(/static/media/FuturaBoldfont.7bfa5614.ttf);
  font-family: FuturaBoldFont;
}

.Footer_FooterBackground__Tqsdc {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Footer_TermsAndConditions__3W2NP {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: white;
  cursor: pointer;
}

/* The Modal (background) */
.Footer_TermsModal__2BxqZ {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.Footer_TermsModalTitle__1W56e {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  width: 80%; /* Could be more or less, depending on screen size */
  font-family: FuturaBoldFont;
  font-size: 3vh;
}

.Footer_TermsModalContent__39fnb {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.Footer_TermsModalCloseButton__1aBs7 {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.Footer_TermsModalCloseButton__1aBs7:hover,
.Footer_TermsModalCloseButton__1aBs7:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.Footer_TermsModalContent__39fnb {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: black;
}

.Footer_bolditems__m0lro {
  font-weight: bold;
}

.Footer_listitems__2BHpc {
  text-align: left;
  /* list-style-type: circle; */
}

.VideoBackground_video__1nzwc {
  position: absolute;
  z-index: -1;
  top: 15vh;
  left: 0;
  width: 100vw;
  height: 85vh;
  object-fit: cover;
  align-items: left;
}

@font-face {
  src: url(/static/media/minecraft.13b1d212.ttf);
  font-family: minecraft;
}

.Countdown_flexboxcontainer__3OvyN {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  position: relative;
  bottom: 21vh;
}

.Countdown_countdownlabels__2-gE2 {
  font-family: futuraMedium;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
}

.Countdown_countdownnumbers__2deB7 {
  font-family: minecraft;
  font-size: 13vh;
  line-height: 75px;
  /* font-weight: bold; */
  padding-top: 0px;
  margin-top: 0px;
}

.Countdown_invisible__1yHCD {
  font-family: futur;
  font-size: 2.5vh;
  opacity: 0;
}

.Countdown_flexbox1__3Jp8L {
  margin: 10px;
  text-align: center;
  justify-content: center;
}

.Countdown_flexboxcolon___pUu4 {
  margin: 10px;
  text-align: center;
  justify-content: center;
}

.Countdown_flexcontainer__2ueWA {
  display: flex;
  /* max-width: 20vw; */
  justify-content: center;
}

.Countdown_flexcontainer__2ueWA div {
  padding: 0px;
}

.Countdown_countdownText__3iQ6F {
  color: #ffffff;
  font-size: 3vh;
  font-family: futur;
}

.LeftInfo_mainContainer__2sZ2r {
  position: fixed;
  left: 5vw;
  font-family: minecraft;
  width: 20vw;
  top: 30vh;
  /* line-height: 5vh; */
  align-items: center;
}

.LeftInfo_infoContainer__2l-NZ {
  color: rgb(255, 255, 255);
  font-size: 6vh;
  align-items: flex-start;
  display: inline;
  font-weight: normal;
  line-height: 7vh;
  /* background-color: blueviolet; */
  margin-bottom: 0;
}
.LeftInfo_bluetext__1nx-V {
  color: rgb(0, 204, 255);
}
.LeftInfo_borderContainer__3cCVw {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 20px;
  display: inline-block;
  background-color: rgb(255, 0, 113, 0.8);
  /* opacity: 0.5; */
}

.LeftInfo_bottomText__2Kfkt {
  position: relative;
  bottom: 2vh;
  line-height: 1vh;
  font-size: 5vh;
}

.LeftInfo_links__3D_-h {
  position: relative;
  bottom: 6vh;
  font-size: 5vh;
  font-family: minecraft;
  text-decoration: underline;
  margin-left: 2vw;
  margin-right: 2vw;
}

.LeftInfo_FooterBackground__2nYo5 {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.LeftInfo_TermsAndConditions__7gnVq {
  font-size: 3vh;
  font-family: futuramediumbt;
  color: white;
  cursor: pointer;
}

/* The Modal (background) */
.LeftInfo_TermsModal__2DbBN {
  display: inline-block;
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 25%;
  margin: auto;
  top: 40vh;
  width: 50%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Modal Content/Box */
.LeftInfo_TermsModalTitle__mx27r {
  padding-right: 3vw;
  margin: 1% auto; /* 15% from the top and centered */
  width: 100%; /* Could be more or less, depending on screen size */
  font-family: FuturaBoldFont;
  color: white;
  font-size: 5vh;
}

.LeftInfo_TermsModalText__3vArf {
  color: white;
  margin-bottom: 3vh;
  padding-bottom: 5vh;
  font-size: 2.5vh;
}

.LeftInfo_TermsModalContent__E_WGl {
  background-color: #000000be;
  color: white;
  font-size: 3vh;
  margin: 0% auto; /* 15% from the top and centered */
  padding: 0px;
  /* border: 2px solid #692626; */
  width: 100%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.LeftInfo_TermsModalCloseButton__20ja8 {
  color: rgb(255, 255, 255);
  padding: 10px;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.LeftInfo_TermsModalCloseButton__20ja8:hover,
.LeftInfo_TermsModalCloseButton__20ja8:focus {
  color: rgb(255, 0, 113, 0.8);
  text-decoration: none;
  cursor: pointer;
}

.LeftInfo_TermsModalContent__E_WGl {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: black;
}

.LeftInfo_bolditems__1WIK_ {
  font-weight: bold;
}

.LeftInfo_listitems__17XoP {
  text-align: left;
  /* list-style-type: circle; */
}

.LeftInfo_subjecttoterms__2lXFG {
  font-size: 3vh;
  line-height: 1vh;
  cursor: pointer;
  text-decoration: underline;
}

@font-face {
  src: url(/static/media/FuturaBoldfont.7bfa5614.ttf);
  font-family: MotivaSansMedium;
}

@font-face {
  src: url(/static/media/FuturaHeavyItalicfont.815a0751.ttf);
  font-family: futuraItalic;
}

.MinterMobile_Minter__56m7G {
  justify-content: center;
  /* max-width: 90vw; */
  align-items: center;
  float: center;
  margin: 5px;
  /* opacity: 90%; */
}

.MinterMobile_Minter__56m7G .MinterMobile_textwrapper__2efDe {
  position: relative;
  top: 5vh;
  margin-top: 0px;
  display: inline-block;
  /* text-align: center; */
}

.MinterMobile_form__2leIy {
  padding: 2vh 7vw;
  /* max-height: 800px; */
  /* max-width: 400px; */
  width: 80vw;
  border-color: #1c1c1d;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
  opacity: 0.9;
}

.MinterMobile_formTitle__2ueE- {
  padding: 8px 16px;
  padding-bottom: 0px;
  color: #ffffff;
  font-size: 7.5vw;
  font-family: futuraItalic;
  margin: 0;
}

.MinterMobile_pricespan__2QqXb {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 6vw;
  float: right;
}

.MinterMobile_middle__1QrP0 {
  text-align: left;
  justify-content: left;
  align-items: left;
}

.MinterMobile_total__2Yg7b {
  font-family: futuraMedium;
  font-size: 6vw;
  float: left;
}

#MinterMobile_mintButton__15atp {
  margin-top: 40px;
  background-color: #ffffff;
  color: #1c1c1d;
  font-family: MotivaSansMedium;
  font-size: 8vw;
  padding: 0.5vh 30%;
  cursor: pointer;
  border-radius: 12px;
  border: none;
}

#MinterMobile_status__acwrN {
  color: rgb(255, 255, 255);
  font-family: futuraMedium;
  font-size: 3vw;
}

#MinterMobile_nftSupply__2F-mc {
  font-family: futuraMedium;
  font-size: 3vw;
  padding-bottom: 1vh;
  margin: 0;
  padding-top: 0;
}

.MinterMobile_fee__b0L1M {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 4vw;
  float: right;
}

@font-face {
  src: url(/static/media/futuramediumbt.4e762c29.ttf);
  font-family: futuraMedium;
}

@font-face {
  src: url(/static/media/FuturaExtraBlackfont.bf8e35c1.ttf);
  font-family: futuraextrablack;
}

.QuantitySelectorMobile_qtySelector__3x9z_ {
  margin: "auto";
  display: "block";
  width: "fit-content";
  font-family: futuraMedium;
  font-size: 2vh;
}

.QuantitySelectorMobile_max__3Eov_ {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 6vw;
  float: right;
}

.QuantitySelectorMobile_numberOfMints__2uM2s {
  display: inline-block;
  font-family: futuraMedium;
  font-size: 6vw;
}

.QuantitySelectorMobile_amount__2sDOs {
  font-family: futuraMedium;
  font-size: 6vw;
  float: left;
}

.QuantitySelectorMobile_Amount__1IStX {
  text-align: center;
  float: left;
}

#QuantitySelectorMobile_selectButtons__1oCjm {
  background-color: #1c1c1d;
  color: #ffffff;
  /* border-color: #1c1c1d; */
  /* box-shadow: 10px 5px 5px #1c1c1d; */
  display: inline-block;
  font-family: futuraextrablack;
  font-size: 6vw;
  padding: 0px 1vw;
  /* max-height: 40px; */
  border: none;
  cursor: pointer;
}

#QuantitySelectorMobile_maxButton__30Omw {
  background-color: #ffffff;
  border-color: #1c1c1d;
  color: #1c1c1d;
  box-shadow: 0px 0px;
  font-family: futuraextrablack;
  font-size: 4vw;
  padding: 0px 2vw;
  cursor: pointer;
  border: none;
  border-radius: 25px;
}

@font-face {
  src: url(/static/media/FuturaBoldfont.7bfa5614.ttf);
  font-family: FuturaBoldfont;
}

.HeaderMobile_Headercontainer__3QrwR {
  display: inline-block;
  width: 100vw;
  margin-top: 5px;
}

.HeaderMobile_Buttoncontainer__h93CY {
  position: absolute;
  justify-content: center;
  text-align: center;
  top: 11vh;
  right: 20vw;
  left: 20vw;
  margin-top: 0px;
}

.HeaderMobile_Header__2UWbb {
  margin: 0 auto;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
  max-width: 100vw;
}

.HeaderMobile_HeaderBackground__WzlKf {
  padding: 0.5vh 0vw;
  margin: 0 auto;
  height: 9vh;
  width: 100vw;
  color: rgb(255, 255, 255);
  background-color: #ff0071;
  justify-content: center;
  align-content: center;
  align-items: center;
  float: center;
  align-self: center;
}

.HeaderMobile_SMSimage__rV-tI {
  height: 4vh;
  width: 4vh;
  padding-left: 0.6vw;
}

#HeaderMobile_title__1onsE {
  font-size: 3vh;
  text-align: center;
  font-family: FuturaBoldfont;
  font-weight: bold;
  display: inline-block;
}

#HeaderMobile_walletButton__2UkCR {
  text-align: center;
  line-height: 0vh;
  margin-right: 10px;
  font-family: FuturaBoldfont;
  font-weight: bold;
  font-size: 4vw;
  padding: 2vh 5vw;
  color: #ffffff;
  background-color: #1c1c1d;
  border-radius: 8px;
  white-space: normal;
  border: none;
  cursor: pointer;
  opacity: 0.85;
}

.HeaderMobile_subheader__xJl3g {
  font-family: minecraft;
  font-size: 9vw;
  position: absolute;
  top: 5vh;
  left: 1vw;
  right: 1vw;
}

.VideoBackgroundMobile_video__2aR6h {
  position: absolute;
  z-index: -1;
  top: 9vh;
  left: 0;
  width: 100vw;
  height: 91vh;
  object-fit: cover;
  align-items: left;
  background-color: aqua;
}

@font-face {
  src: url(/static/media/minecraft.13b1d212.ttf);
  font-family: minecraft;
}

.CountdownMobile_flexboxcontainer__1Sj-K {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  position: relative;
  bottom: 11.5vh;
  left: 0vw;
}

/* test */

.CountdownMobile_countdownlabels__2890P {
  font-family: futuraMedium;
  font-size: 1.5vh;
  font-weight: bold;
}

.CountdownMobile_countdownnumbers__fdOZn {
  font-family: minecraft;
  font-size: 22.5vw;
  line-height: 50%;
  /* background-color: aquamarine; */
  /* font-weight: bold; */
}

.CountdownMobile_invisible__1WhS6 {
  font-family: futur;
  font-size: 2vh;
  opacity: 0;
}

.CountdownMobile_flexbox1__2lXou {
  margin: 5px;
  text-align: center;
  justify-content: center;
}

.CountdownMobile_flexboxcolon__cimJa {
  margin: 5px;
  text-align: center;
  justify-content: center;
}

.CountdownMobile_flexcontainer__3DFQ- {
  display: flex;
  /* max-width: 20vw; */
  justify-content: center;
}

.CountdownMobile_flexcontainer__3DFQ- div {
  padding: 0px;
}

@font-face {
  src: url(/static/media/futuramediumbt.4e762c29.ttf);
  font-family: futuramediumbt;
}

@font-face {
  src: url(/static/media/FuturaBoldfont.7bfa5614.ttf);
  font-family: FuturaBoldFont;
}

.FooterMobile_FooterBackground__1dcYR {
  position: absolute;
  bottom: 1;
  margin-top: 10vh;
  width: 100%;
}

.FooterMobile_TermsAndConditions___DSv- {
  position: absolute;
  left: 10vw;
  right: 10vw;
  display: block;
  bottom: 0vh;
  font-size: 2vh;
  font-family: futuramediumbt;
  color: white;
  cursor: pointer;
}

/* The Modal (background) */
.FooterMobile_TermsModal__2NiEJ {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.FooterMobile_TermsModalTitle__V1T7v {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  width: 80%; /* Could be more or less, depending on screen size */
  font-family: FuturaBoldFont;
  font-size: 3vh;
}

/* Modal Content/Box */
.FooterMobile_TermsModalContent__1aHp9 {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.FooterMobile_TermsModalCloseButton__2mem6 {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.FooterMobile_TermsModalCloseButton__2mem6:hover,
.FooterMobile_TermsModalCloseButton__2mem6:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.FooterMobile_TermsModalContent__1aHp9 {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: black;
}

.FooterMobile_HeaderBackground__3l_nB {
  /* padding: 1vh 2vw; */
  margin: 0 auto;
  /* height: 7.5vh; */
  width: 100vw;
  background-color: #ff0071;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  vertical-align: middle;
}

.FooterMobile_SMSimage__H0YDC {
  height: 4vh;
  width: 4vh;
  padding-left: 0.6vw;
  vertical-align: middle;
  margin: 2vw;
}

.FooterMobile_bolditems__oE8JU {
  font-weight: bold;
}

.FooterMobile_listitems__wk8Z3 {
  text-align: left;
  /* list-style-type: circle; */
}

.LeftInfoMobile_mainContainer__13ugd {
  position: relative;
  display: inline-block;
  font-family: minecraft;
  width: 80vw;
  top: 5vh;
  /* line-height: 5vh; */
  align-items: center;
}

.LeftInfoMobile_infoContainer__2rAqn {
  color: rgb(255, 255, 255);
  font-size: 8vw;
  align-items: flex-start;
  display: inline;
  font-weight: normal;
}
.LeftInfoMobile_bluetext__PpLep {
  color: rgb(0, 204, 255);
}
.LeftInfoMobile_borderContainer__1y_Hr {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 20px;
  display: inline-block;
  background-color: rgb(255, 0, 113, 0.8);
}

.LeftInfoMobile_bottomText__399_Y {
  position: relative;
  bottom: 2vh;
  line-height: 1vh;
  font-size: 7vw;
}

.LeftInfoMobile_links__6FiHx {
  position: relative;
  bottom: 4vh;
  font-size: 7vw;
  font-family: minecraft;
  text-decoration: underline;
  margin-left: 2vw;
  margin-right: 2vw;
}

.LeftInfoMobile_TermsAndConditions__3aBNP {
  font-size: 3vh;
  font-family: futuramediumbt;
  color: white;
  cursor: pointer;
}

/* The Modal (background) */
.LeftInfoMobile_TermsModal__1f6q8 {
  display: inline-block;
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 15%;
  margin: auto;
  top: 20vh;
  width: 70%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Modal Content/Box */
.LeftInfoMobile_TermsModalTitle__12MfZ {
  padding-right: 3vw;
  margin: 1% auto; /* 15% from the top and centered */
  width: 70%; /* Could be more or less, depending on screen size */
  font-family: FuturaBoldFont;
  color: white;
  font-size: 5vh;
}

.LeftInfoMobile_TermsModalText__dUZFW {
  color: white;
  margin-bottom: 3vh;
  padding-bottom: 5vh;
  font-size: 2.5vh;
}

.LeftInfoMobile_TermsModalContent__LPfD0 {
  background-color: #000000be;
  color: white;
  font-size: 3vh;
  margin: 0% auto; /* 15% from the top and centered */
  padding: 0px;
  /* border: 2px solid #692626; */
  width: 100%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.LeftInfoMobile_TermsModalCloseButton__3QJV- {
  color: rgb(255, 255, 255);
  padding: 10px;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.LeftInfoMobile_TermsModalCloseButton__3QJV-:hover,
.LeftInfoMobile_TermsModalCloseButton__3QJV-:focus {
  color: rgb(255, 0, 113, 0.8);
  text-decoration: none;
  cursor: pointer;
}

.LeftInfoMobile_TermsModalContent__LPfD0 {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: black;
}

.LeftInfoMobile_bolditems__26HSj {
  font-weight: bold;
}

.LeftInfoMobile_listitems__-992R {
  text-align: left;
  /* list-style-type: circle; */
}

.LeftInfoMobile_subjecttoterms__1euZV {
  font-size: 3vh;
  line-height: 1vh;
  cursor: pointer;
  text-decoration: underline;
}

