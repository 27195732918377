@font-face {
  src: url("../fonts/futuramediumbt.ttf");
  font-family: futuramediumbt;
}

@font-face {
  src: url("../fonts/FuturaBoldfont.ttf");
  font-family: FuturaBoldFont;
}

.FooterBackground {
  position: absolute;
  bottom: 1;
  margin-top: 10vh;
  width: 100%;
}

.TermsAndConditions {
  position: absolute;
  left: 10vw;
  right: 10vw;
  display: block;
  bottom: 0vh;
  font-size: 2vh;
  font-family: futuramediumbt;
  color: white;
  cursor: pointer;
}

/* The Modal (background) */
.TermsModal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.TermsModalTitle {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  width: 80%; /* Could be more or less, depending on screen size */
  font-family: FuturaBoldFont;
  font-size: 3vh;
}

/* Modal Content/Box */
.TermsModalContent {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.TermsModalCloseButton {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.TermsModalCloseButton:hover,
.TermsModalCloseButton:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.TermsModalContent {
  font-size: 2vh;
  font-family: futuramediumbt;
  color: black;
}

.HeaderBackground {
  /* padding: 1vh 2vw; */
  margin: 0 auto;
  /* height: 7.5vh; */
  width: 100vw;
  background-color: #ff0071;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  vertical-align: middle;
}

.SMSimage {
  height: 4vh;
  width: 4vh;
  padding-left: 0.6vw;
  vertical-align: middle;
  margin: 2vw;
}

.bolditems {
  font-weight: bold;
}

.listitems {
  text-align: left;
  /* list-style-type: circle; */
}
